import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { TelegramIcon } from "../../Icon/TelegramIcon";

const Hero = () => {
  return <Box sx={{ backgroundColor: "#fff" }}>
    <Container maxWidth="xl" sx={{ overflow: "hidden", height: { xs: 530, sm: 585, md: 1180 }, pb: "5vh" }}>
      <Box pt={{ xs: "120px", md: "180px" }} sx={{
        display: "flex", justifyContent: "center",
        "& img": { filter: "grayscale(100%)", width: { xs: "180%", sm: "100%", md: "180%", lg: "100%" }, height: { xs: 253, sm: "100%" } }
      }} position="relative" >
        <img src={"/images/trader-files/team.png"} alt={"team"} />
        <Stack position="absolute" top={{ xs: 249, sm: 268, md: 450 }} alignItems="center" width="100%" >
          <Typography
            zIndex={1}
            sx={{ textShadow: {xs: "3px 0px 1px rgb(180 28 24)", md: "10px 0px 1px rgb(180 28 24)"}}}
            fontSize={{ xs: 121, md: 277 }} fontWeight={900} lineHeight={{ xs: "100px", md: "250px" }} color="#b41c18">13</Typography>
          <Typography zIndex={1} fontSize={{ xs: 27, md: 60 }} fontWeight="bold" lineHeight={{ xs: "26px", md: "65px" }} textAlign="center" color="#000">файлов <br /> для  трейдера</Typography>
          <Typography zIndex={1} fontSize={{ xs: 13, md: 28 }} fontWeight="300" mt={{ xs: 2, md: 4 }} textAlign="center" color="#000">
            от ТОПовой команды, которые сэкономят тебе <br />
            месяцы работы и кучу слитых денег!
          </Typography>
          <Box display="flex" justifyContent="center" mt={{ xs: 2, md: 6 }}  >
          <Button color="info" variant="contained" href="https://t.me/Cicap_files_bot" target='_blank'
              onClick={() => {
                //@ts-ignore
                ym(96414864, 'reachGoal', 'button_file')
              }}
              sx={{borderRadius: 6, px: {xs: 4, sm: 5}, height: {xs: 51, md: 65}}}>
              <TelegramIcon sx={{fontSize: {xs: 24, sm: 32}}} />
              <Typography ml={1} sx={{fontSize: {xs: 14, md: 16}}}>
                Забрать файлы бесплатно
              </Typography>
            </Button>
          </Box>
        </Stack>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            ml: { xs: -3, lg: 0 },
            minWidth: 1346,
            height: "30%",
            backgroundImage: "linear-gradient(to top, rgb(255 255 255 / 94%), rgba(255, 255, 255, 0))",
            zIndex: 0,
          }}
        />
      </Box>

    </Container>
  </Box>
}


export default Hero;